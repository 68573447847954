<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main_profile'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import global_func from '@/global_function'
import VueEasyLightbox from 'vue-easy-lightbox'
import geo_data from '@/assets/file/geo_data.JSON'
import ModalUpload from '@/components/modal/upload_file'
import vue2Dropzone from 'vue2-dropzone'
/**
 * Starter page component
 */
export default {
  page: {
    title: 'ข้อมูลส่วนตัว/บริษัท',
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, VueEasyLightbox, ModalUpload, vueDropzone: vue2Dropzone },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('personal_profile')
    var lang_data = i18n.t('register_transporter')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      personal_data: {},
      check_lang: true,
      lang: lang_obj,
      lang_data: lang_data,
      transporter_lang: false,
      // imgs and file
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      imgs2: '', // Img Url , string or Array of string
      visible2: false,
      index2: 0, // default: 0
      lightboximgs: [
        // require('@/assets/images/small/img-1.jpg'),
        // require('@/assets/images/small/img-2.jpg'),
        // require('@/assets/images/small/img-3.jpg'),
      ],
      lightboximgs_index: [{}, {}, {}],
      id_card: [],
      id_card_fileType: '',
      profile: [],
      file_raw_data: [],
      file_upload: [],
      file_upload2: [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        maxFilesize: 5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        maxFiles: 1,
        acceptedFiles: 'image/*,application/pdf',
      },
      dropzoneOptions2: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        maxFilesize: 5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        maxFiles: 3,
        acceptedFiles: 'image/*',
      },
      // active flag
      edit_profile_status: 1,
      upload_profile_pic_status: false,
      // information tab
      formData: {
        firstname: '',
        lastname: '',
        mover_name: '',
        mover_grant_num: '',
        id_card: '',
        mover_tel: '',
        mailaddr: '',
        mover_address1: '',
      },
      dataBusinessType_master: [],
      dataBusinessType_sel: [],
      dataBusinessType_label: '',
      placeholder: {
        // information tab
        name: lang_data.information.placeholder.name,
        lastname: lang_data.information.placeholder.lastname,
        mover_type: lang_data.information.placeholder.mover_type,
        mover_name: lang_data.information.placeholder.mover_name,
        mover_grant_num: '',
        id_card: '',
        mover_tel: lang_data.information.placeholder.mover_tel,
        mailaddr: lang_data.information.placeholder.mailaddr,
        mover_address: lang_data.information.placeholder.mover_address,
        mover_province: lang_data.information.placeholder.mover_province,
        mover_district: lang_data.information.placeholder.mover_district,
        mover_district2: lang_data.information.placeholder.mover_district2,
        mover_postcode: lang_data.information.placeholder.mover_postcode,
        // data new truck
        group: lang_data.add_car.placeholder.group,
        type: lang_data.add_car.placeholder.type,
        numberOfCar: lang_data.add_car.placeholder.numberOfCar,
        car: lang_data.add_car.placeholder.car,
        tons: lang_data.add_car.placeholder.tons,
        // doc tab
        id_card_copy: lang_data.doc.placeholder.id_card_copy,
        pic: lang_data.doc.placeholder.mover_picpostcode,
      },
      valid_msg: {
        mover_grant_num: '',
      },
      nextButtonText: lang_data.general.btn.next,
      backButtonText: lang_data.general.btn.back,
      fin_btn: lang_data.general.btn.fin,
      save_succes: lang_data.general.save_succes,
      del_btn: 'ลบข้อมูล',
      mover_type: [
        { name: lang_data.information.mover_co, value: '1' },
        { name: lang_data.information.mover_person, value: '2' },
      ],
      mover_type_sel: [],
      geo_data: geo_data,
      dataProvince: [],
      dataAmphur: [],
      dataTambol: [],
      dataProvince_data: [],
      dataAmphur_data: [],
      dataTambol_data: [],
      dataProvince_sel: [],
      dataAmphur_sel: [],
      dataTambol_sel: [],
      zipcode: '',
      disable_zipcode_input: false,
      input_display: false,
      input_display2: false,
      input_display3: false,
      row_display: false,
      next_btn: true,
      disableEdit: false,
      mover_address_full: '',
    }
  },
  watch: {
    dataProvince_sel: {
      handler: function () {
        var ck = this.dataProvince_sel === undefined || this.dataProvince_sel === null
        var ck2 = this.dataAmphur_sel === undefined || this.dataAmphur_sel === null
        var ck3 = this.dataTambol_sel === undefined || this.dataTambol_sel === null
        if (!ck && !ck2 && !ck3) {
          this.disable_zipcode_input = true
        } else {
          this.disable_zipcode_input = false
        }
      },
    },
    dataAmphur_sel: {
      handler: function () {
        var ck = this.dataProvince_sel === undefined || this.dataProvince_sel === null
        var ck2 = this.dataAmphur_sel === undefined || this.dataAmphur_sel === null
        var ck3 = this.dataTambol_sel === undefined || this.dataTambol_sel === null
        if (!ck && !ck2 && !ck3) {
          this.disable_zipcode_input = true
        } else {
          this.disable_zipcode_input = false
        }
      },
    },
    dataTambol_sel: {
      handler: function () {
        var ck = this.dataProvince_sel === undefined || this.dataProvince_sel === null
        var ck2 = this.dataAmphur_sel === undefined || this.dataAmphur_sel === null
        var ck3 = this.dataTambol_sel === undefined || this.dataTambol_sel === null
        if (!ck && !ck2 && !ck3) {
          this.disable_zipcode_input = true
        } else {
          this.disable_zipcode_input = false
        }
      },
    },
    deep: true,
  },
  mounted() {
    this.showInfo()
    this.getPicProfile()
    this.getDataAddInfo()
    this.getBusinessType()
    // this.setDataEditProfile()
  },
  methods: {
    // get data edit profile
    getDataAddInfo() {
      // console.log(this.geo_data)
      this.dataProvince = this.geo_data.province
      this.dataAmphur = this.geo_data.amphures
      this.dataTambol = this.geo_data.districts
      this.setService_route(this.geo_data.province, this.geo_data.geographies)
      // console.log(this.dataProvince)
      // console.log(this.dataAmphur)
      // console.log(this.dataTambol)
      this.dataProvince_data = this.dataProvince
      this.dataAmphur_data = this.dataAmphur
      this.dataTambol_data = this.dataTambol
      // console.log('ข้อมูลประเทศ')
      // console.log(this.dataAmphur_data)
    },
    getBusinessType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getBusinessType').then((rp) => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var obj = {
            business_type_id: data[index]['business_type_id'],
            business_type_name_th: data[index]['business_type_name_th'],
            business_type_name_en: data[index]['business_type_name_en'],
            business_type_code: data[index]['business_type_code'],
            business_type_sel_status: '',
          }
          this.dataBusinessType_master.push(obj) // for insert data to db
        }
        if (this.account_lang == 'th') {
          this.goods_lang = true
          this.dataBusinessType_label = 'business_type_name_th'
        } else {
          this.dataBusinessType_label = 'business_type_name_en'
        }
        // console.log(this.dataBusinessType_master)
      })
    },
    getGeoDataById(mode, id) {
      var geo_data = this.geo_data
      var data_return = ''
      let data = geo_data[mode]
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        if (element.id == id) {
          data_return = element
        }
      }
      return data_return
    },
    getPicProfile() {
      var that = this
      service
        .postService('getFileDataById', {
          id_member: that.personal_data.id_member,
        })
        .then((rp) => {
          // console.log('ไฟล์')
          // console.log(rp)
          this.file_raw_data = rp.file_data
          // this.setPicProfile()
          this.setPicProfileBase64()
        })
    },
    getFilePresonal() {
      if (this.file_raw_data != []) {
        // console.log(this.file_raw_data)
        var file = { size: 517993, name: '2021_6_23_9_39_6_103_1', type: 'image/png' }
        var host = 'http://localhost:8081/'
        var fileUrl = host + this.id_card
        // console.log(file)
        // var fileUrl = '/../file_upload_dtc_backhaul/images/id_card/2021_6_23_9_39_6_103_1.png'
        // var fileUrl = 'http://localhost:8081/img/2021_6_23_9_39_6_103_1.05870659.png'
        this.$refs.dropzone_file_upload.manuallyAddFile(file, fileUrl)
        for (let index = 0; index < this.lightboximgs.length; index++) {
          const element = this.lightboximgs[index]
          var fileUrl2 = host + element
          this.$refs.dropzone_file_upload_2.manuallyAddFile(file, fileUrl2)
        }
      } else {
        service
          .postService('getFileDataById', {
            id_member: that.personal_data.id_member,
          })
          .then((rp) => {
            // console.log('ไฟล์')
            // console.log(rp)
            this.file_raw_data = rp.file_data
          })
      }
    },
    // sort data
    company_sel() {
      if (this.mover_type_sel !== undefined && this.mover_type_sel !== null) {
        if (this.mover_type_sel['value'] == 1) {
          this.input_display = true
          this.input_display2 = false
          this.input_display3 = true
          this.placeholder.mover_grant_num = this.lang_data.information.placeholder.mover_grant_num
          this.valid_msg.mover_grant_num = this.lang_data.information.invalid.mover_grant_num
        } else {
          this.input_display = true
          this.input_display2 = true
          this.input_display3 = false
          this.placeholder.mover_grant_num = this.lang_data.information.placeholder.id_card
          this.valid_msg.mover_grant_num = this.lang_data.information.invalid.id_card
        }
        this.next_btn = false
        this.row_display = true
      } else {
        this.next_btn = true
        this.row_display = false
        this.input_display = false
        this.input_display2 = false
        this.input_display3 = false
      }
    },
    province_sel() {
      if (this.zipcode == '') {
        this.dataAmphur_sel = []
        this.dataTambol_sel = []
        this.zipcode = ''
        // console.log(this.dataProvince_sel)
        let ps = this.dataProvince_sel

        let ap = this.dataAmphur
        this.dataAmphur_data = []
        this.dataTambol_data = []
        for (let index = 0; index < ap.length; index++) {
          const ap_row = ap[index]
          if (ap_row['province_id'] == ps['id']) {
            this.dataAmphur_data.push(ap_row)
          }
        }

        let ap_data = this.dataAmphur_data
        let tb = this.dataTambol
        this.dataTambol_data = []
        for (let index = 0; index < ap_data.length; index++) {
          const ap_data_row = ap_data[index]
          for (let index2 = 0; index2 < tb.length; index2++) {
            const tb_row = tb[index2]
            if (tb_row['amphure_id'] == ap_data_row['id']) {
              this.dataTambol_data.push(tb_row)
            }
          }
        }
      }
    },
    amphur_sel() {
      if (this.zipcode == '') {
        this.dataTambol_sel = []
        // this.zipcode = ''
        // console.log(this.dataAmphur_sel)
        let as = this.dataAmphur_sel
        let tb = this.dataTambol
        this.dataTambol_data = []
        for (let index = 0; index < tb.length; index++) {
          const tb_row = tb[index]
          if (tb_row['amphure_id'] == as['id']) {
            this.dataTambol_data.push(tb_row)
          }
        }
      }
    },
    tambol_sel() {
      // console.log(this.dataTambol_sel)
      this.zipcode = this.dataTambol_sel['zip_code']
    },
    zipcode_input() {
      // console.log(this.zipcode)
      var flag = 0
      if (this.zipcode.length == 5) {
        this.dataTambol_data = []
        this.dataAmphur_data = []
        this.dataProvince_data = []
        this.dataProvince_sel = []
        this.dataAmphur_sel = []
        this.dataTambol_sel = []
        for (let index = 0; index < this.dataTambol.length; index++) {
          const element = this.dataTambol[index]
          if (element['zip_code'] == this.zipcode) {
            this.dataTambol_data.push(element)
          }
        }
        for (let index2 = 0; index2 < this.dataTambol_data.length; index2++) {
          const element2 = this.dataTambol_data[index2]
          for (let index3 = 0; index3 < this.dataAmphur.length; index3++) {
            const element3 = this.dataAmphur[index3]
            if (element3['id'] == element2['amphure_id']) {
              if (this.dataAmphur_data.length > 0) {
                for (let index4 = 0; index4 < this.dataAmphur_data.length; index4++) {
                  const element4 = this.dataAmphur_data[index4]
                  if (element4['id'] == element3['id']) {
                    flag++
                  }
                  if (flag == 0) {
                    this.dataAmphur_data.push(element3)
                  }
                }
              } else {
                this.dataAmphur_data.push(element3)
              }
            }
          }
        }
        for (let index5 = 0; index5 < this.dataAmphur_data.length; index5++) {
          const element5 = this.dataAmphur_data[index5]
          for (let index6 = 0; index6 < this.dataProvince.length; index6++) {
            const element6 = this.dataProvince[index6]
            if (element5['province_id'] == element6['id']) {
              this.dataProvince_data.push(element6)
            }
          }
        }
        // console.log(this.dataTambol_data)
        // console.log(this.dataAmphur_data)
        // console.log(this.dataProvince_data)
        this.dataProvince_sel = this.dataProvince_data[0]
        this.dataAmphur_sel = this.dataAmphur_data[0]
        this.dataTambol_sel = this.dataTambol_data[0]
      } else {
        this.dataProvince_data = this.dataProvince
        this.dataAmphur_data = this.dataAmphur
        this.dataTambol_data = this.dataTambol
      }
    },
    // set and config data
    setService_route(data, data2) {
      var prov = data
      // eslint-disable-next-line no-unused-vars
      var geo = data2
      var data_prov = {
        1: [
          {
            id: '101',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '1',
            status: '',
          },
        ],
        2: [
          {
            id: '102',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '2',
            status: '',
          },
        ],
        3: [
          {
            id: '103',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '3',
            status: '',
          },
        ],
        4: [
          {
            id: '104',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '4',
            status: '',
          },
        ],
        5: [
          {
            id: '105',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '5',
            status: '',
          },
        ],
        6: [
          {
            id: '106',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '6',
            status: '',
          },
        ],
      }
      // console.log(prov)
      for (let index = 0; index < prov.length; index++) {
        let rowdata = {
          id: prov[index].id,
          name_th: prov[index].name_th,
          name_en: prov[index].name_en,
          geography_id: prov[index].geography_id,
          status: '',
        }
        const rowProv = rowdata
        if (rowProv.geography_id == '1') {
          data_prov['1'].push(rowProv)
        } else if (rowProv.geography_id == '2') {
          data_prov['2'].push(rowProv)
        } else if (rowProv.geography_id == '3') {
          data_prov['3'].push(rowProv)
        } else if (rowProv.geography_id == '4') {
          data_prov['4'].push(rowProv)
        } else if (rowProv.geography_id == '5') {
          data_prov['5'].push(rowProv)
        } else if (rowProv.geography_id == '6') {
          data_prov['6'].push(rowProv)
        }
      }
      // console.log(data_prov)
      this.data_prov_data = data_prov
      // this.data_prov_data2 = data_prov
      // this.data_prov_data3 = data_prov
      // this.data_prov_data4 = data_prov
      // this.data_prov_data5 = data_prov
      // this.data_prov_data6 = data_prov
      if (this.account_lang == 'th') {
        this.transporter_lang = true
      }
    },
    keyThaiEng: function ($event) {
      // func เช็คคำ เฉพาะ ไทย - อังกฤษ
      var k = $event.keyCode
      if ((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k >= 161 && k <= 255) || (k >= 3585 && k <= 3675)) {
        return true
      } else {
        $event.preventDefault()
      }
    },
    showInfo() {
      this.personal_data = this.account_data[0]
      // console.log(this.personal_data)
      var data = this.personal_data
      var pref = data.mover_pref
      var pref_split = pref.split('|')
      // console.log(pref_split)
      if(pref_split.length > 0) {
        var districts = ''
        var amphures = ''
        var province = ''
        let addr_str = data.mover_address1
        if(pref_split[0] && pref_split[0] != 'undefined') {
          districts = this.getGeoDataById('districts', pref_split[0])
          addr_str += ` ${(this.account_lang == 'th') ? districts.name_th : districts.name_en}`
        }
        if(pref_split[1] && pref_split[1] != 'undefined') {
          amphures = this.getGeoDataById('amphures', pref_split[1])
          addr_str += ` ${(this.account_lang == 'th') ? amphures.name_th : amphures.name_en}`
        }
        if(pref_split[2] && pref_split[2] != 'undefined') {
          province = this.getGeoDataById('province', pref_split[2])
          addr_str += ` ${(this.account_lang == 'th') ? province.name_th : province.name_en}`
        }
        if(addr_str) {
          addr_str += ` ${data.mover_zip}`
          this.mover_address_full = addr_str
        }
      } else {
        this.mover_address_full = ''
      }

    },
    setDataEditProfile() {
      var data = this.personal_data
      this.formData.firstname = data.firstname
      this.formData.lastname = data.lastname
      this.formData.mover_name = data.mover_name
      this.formData.mover_grant_num = data.mover_grant_num
      this.formData.id_card = data.id_card
      this.formData.mover_tel = data.mover_tel
      this.formData.mailaddr = data.mover_mailaddr
      this.formData.mover_address1 = data.mover_address1
      this.mover_type_sel = this.mover_type[data.mover_business - 1]
      this.company_sel()
      this.dataBusinessType_sel = {
        business_type_code: data.business_type_code,
        business_type_id: data.business_type_id,
        business_type_name_en: data.business_type_name_en,
        business_type_name_th: data.business_type_name_th,
        business_type_sel_status: '',
      }
      this.zipcode = data.mover_zip
      this.zipcode_input()
    },
    setPicProfile() {
      var data = this.file_raw_data
      this.lightboximgs = []
      var pic_obj = []
      var pic_obj2 = []
      var pic_obj3 = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        if (element.title == 'pic_mover') {
          // var path = require('/../file_upload_dtc_backhaul/images/pic_mover/' + element.filename)
          // var path = require('../../file_upload_dtc_backhaul/images/pic_mover/' + element.filename)
          // var path = require('../../../../../../var/vue/file_upload_dtc_backhaul/images/pic_mover/' + element.filename)
          var path = require('/var/vue/file_upload_dtc_backhaul/images/pic_mover/' + element.filename)
          pic_obj.push(path)
        } else if (element.title == 'id_card') {
          this.id_card_fileType = ''
          // var path2 = require('/../file_upload_dtc_backhaul/images/id_card/' + element.filename)
          // var path2 = require('../../file_upload_dtc_backhaul/images/id_card/' + element.filename)
          // var path2 = require('../../../../../../var/vue/file_upload_dtc_backhaul/images/id_card/' + element.filename)
          var path2 = require('/var/vue/file_upload_dtc_backhaul/images/id_card/' + element.filename)
          pic_obj2.push(path2)
          // pic_obj2 = 'pdf'
          if (element.filetype == 'application/pdf') {
            this.id_card_fileType = 'application/pdf'
          } else {
            this.id_card_fileType = element.filetype
          }
        } else if (element.title == 'profile') {
          // var path3 = require('/../file_upload_dtc_backhaul/images/profile/' + element.filename)
          // var path3 = '../../file_upload_dtc_backhaul/images/profile/' + element.filename
          // var path3 = require('../../file_upload_dtc_backhaul/images/profile/' + element.filename)
          // var path3 = require('../../../../../../var/vue/file_upload_dtc_backhaul/images/profile/' + element.filename)
          var path3 = require('/var/vue/file_upload_dtc_backhaul/images/profile/' + element.filename)
          pic_obj3.push(path3)
        }
      }
      this.lightboximgs = pic_obj
      this.id_card = pic_obj2
      this.profile = pic_obj3
      // console.log(this.lightboximgs)
      // console.log(this.id_card)
      // console.log(this.profile)
    },
    setPicProfileBase64() {
      var data = this.file_raw_data
      this.lightboximgs = []
      var pic_obj = []
      var pic_obj2 = []
      var pic_obj3 = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        if (element.title == 'pic_mover') {
          var path = element.blob
          pic_obj.push(path)
        } else if (element.title == 'id_card') {
          this.id_card_fileType = ''
          var path2 = element.blob
          pic_obj2.push(path2)
          // pic_obj2 = 'pdf'
          if (element.filetype == 'application/pdf') {
            this.id_card_fileType = 'application/pdf'
          } else {
            this.id_card_fileType = element.filetype
          }
        } else if (element.title == 'profile') {
          var path3 = element.blob
          pic_obj3.push(path3)
        }
      }
      this.lightboximgs = pic_obj
      this.id_card = pic_obj2
      this.profile = pic_obj3
    },
    // send edit profile data
    async check_edit_profile() {
      // console.log(this.personal_data)
      // console.log(this.mover_type_sel)
      // console.log(this.dataBusinessType_sel)
      // console.log(this.dataProvince_sel)
      // console.log(this.dataAmphur_sel)
      // console.log(this.dataTambol_sel)

      var valid = await this.check_validate()
      // console.log('เช็ค')
      // console.log(valid)
      if (valid) {
        this.sendEditProfileData()
      } else {
        global_func.alertMessage(this.lang.alert_msg.invalid.all, '', 'warning')
      }
    },
    async check_validate() {
      //เช็ค Valide ก่อนจะกด ถัดไป
      const isValid = await this.$refs.edit_profile_form.validate()
      // console.log(isValid)
      // console.log(this.mover_type_sel['name'])
      // console.log(this.mover_type_sel)
      // console.log(this.dataProvince_sel)
      // console.log(this.dataAmphur_sel)
      // console.log(this.dataTambol_sel)
      // console.log(this.mover_type_sel !== undefined && this.mover_type_sel !== null)
      if (!isValid) {
        if (this.mover_type_sel !== undefined && this.mover_type_sel !== null) {
          if (this.mover_type_sel['value'] == 2) {
            if (this.formData.mover_name == '') {
              this.formData.mover_name = this.lang_data.general.nodata
              const isValid2 = await this.$refs.edit_profile_form.validate()
              if (isValid2) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return false
          }
        }
        return false
      } else {
        return true
      }
    },
    sendEditProfileData() {
      service
        .postService('editProfile', {
          firstname: this.formData.firstname,
          lastname: this.formData.lastname,
          mover_name: this.formData.mover_name,
          mover_grant_num: this.formData.mover_grant_num,
          id_card: this.formData.id_card,
          mover_tel: this.formData.mover_tel,
          mailaddr: this.formData.mailaddr,
          mover_address1: this.formData.mover_address1,
          mover_type_sel: this.mover_type_sel,
          dataProvince_sel: this.dataProvince_sel,
          dataAmphur_sel: this.dataAmphur_sel,
          dataTambol_sel: this.dataTambol_sel,
          zipcode: this.zipcode,
          account_lang: this.account_lang,
          account_data: this.account_data,
          data_prov_data: this.data_prov_data,
          dataBusinessType_sel: this.dataBusinessType_sel,
        })
        .then((rp) => {
          var that = this
          // console.log(rp)
          if (rp.status == 'ok') {
            global_func.alertMessage(this.lang.alert_msg.edit_success, '', 'success')
            localStorage.setItem('users', JSON.stringify(rp.data))
            // this.setDataEditProfile()
            setTimeout(function () {
              window.location.reload()
            }, 1000)
          } else {
            global_func.alertMessage(this.lang.alert_msg.edit_error, '', 'warning')
          }
        })
    },

    // img action and upload file
    showImg(index) {
      this.index = index
      this.visible = true
    },
    showImg2(index2) {
      this.index2 = index2
      this.visible2 = true
    },
    test_file_upload() {
      // console.log(this.$refs.dropzone_file_upload)
    },
    async check_fileUpload() {
      var file = this.$refs.dropzone_file_upload.dropzone.files
      var file2 = this.$refs.dropzone_file_upload_2.dropzone.files
      var that = this
      var flag = 0
      if (file.length > 0) {
        that.file_upload = []
        for (let index = 0; index < file.length; index++) {
          let reader = new FileReader()
          const rowFile = file[index]
          // console.log(rowFile)
          await reader.addEventListener(
            'load',
            () => {
              let dataPush = {
                name: rowFile.name,
                data: reader.result,
                type: rowFile.type,
              }
              that.file_upload.push(dataPush)
              // console.log(this.pdfFile)
            },
            false,
          )
          if (rowFile.status == 'success') {
            reader.readAsDataURL(rowFile)
          } else {
            flag++
          }
        }
      }

      if (file2.length > 0) {
        that.file_upload2 = []
        for (let index = 0; index < file2.length; index++) {
          let reader = new FileReader()
          const rowFile = file2[index]
          // console.log(rowFile)
          await reader.addEventListener(
            'load',
            () => {
              let dataPush = {
                name: rowFile.name,
                data: reader.result,
                type: rowFile.type,
              }
              that.file_upload2.push(dataPush)
              // console.log(this.pdfFile)
            },
            false,
          )
          if (rowFile.status == 'success') {
            reader.readAsDataURL(rowFile)
          } else {
            flag++
          }
        }
      }

      if (flag == 0) {
        setTimeout(function () {
          that.send_file()
        }, 500)
      } else {
        global_func.alertMessage(this.lang.alert_msg.error_upload, '', 'warning')
      }
    },
    send_file() {
      // console.log(this.file_upload)
      // console.log(this.file_upload2)
      service
        .postService('editUploadMutiFile', {
          account_data: this.account_data,
          file_upload: this.file_upload,
          file_upload2: this.file_upload2,
          file_raw_data: this.file_raw_data,
        })
        .then((rp) => {
          // console.log(rp)
          let status = rp.status
          if (status == 'ok') {
            global_func.alertMessage(this.lang.alert_msg.success, '', 'success')
          } else {
            global_func.alertMessage(this.lang.alert_msg.error_upload, '', 'warning')
          }
        })
    },
    open_pdf_file() {
      var file = this.id_card
      if (file.length > 0) {
        // console.log(file)
        // window.open('..' + file[0].default, '_blank')
        // window.open(file, '_blank')
        var pdfWindow = window.open('')
        pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=' + file + '></iframe>')
      }
    },
    // active part
    active_edit_profile() {
      // console.log('กดแล้วจ้า')
      if (this.edit_profile_status == 0) {
        this.edit_profile_status = 1
      } else {
        this.edit_profile_status = 0
        this.setDataEditProfile()
      }
    },
    active_edit_profile_picture() {
      // console.log('กดแล้วจ้า')
      if (this.edit_profile_status == 2) {
        this.edit_profile_status = 1
      } else {
        this.edit_profile_status = 2
        // this.getFilePresonal()
      }
    },
    active_upload_profile_pic() {
      // console.log('กดแล้วจ้า')
      this.$refs.Modal_Upload_file.active('profile', 0)
      if (this.upload_profile_pic_status) {
        this.upload_profile_pic_status = false
      } else {
        this.upload_profile_pic_status = true
      }
    },
  },
}
</script>

<template>
  <Layout>
    <div class="personal">
      <div v-if="edit_profile_status == 0" name="profile_0" class="h-100vh personal-edit-panel container-fluid" :class="{ active: edit_profile_status == 0 }" style="padding: 0rem 4rem 0rem 4rem">
        <simplebar style="max-height: 84vh; min-height: 84vh">
          <div style="padding: 0rem 8rem 0rem 8rem">
            <b-row class="col-margin-bot-2">
              <b-col>
                <span class="personal-title">
                  {{ lang.edit_info.title }}
                </span>
              </b-col>
            </b-row>
            <b-row class="col-margin-bot-2">
              <b-col lg="2">
                <img v-if="profile == undefined || profile == ''" class="rounded-circle avatar-xl" src="@/assets/images/profile/Group 3361@2x.png" width="140px" height="140px" />
                <img v-else class="rounded-circle" alt="130x130" width="130" height="130" :src="profile" data-holder-rendered="true" />
                <img class="rounded-circle edit-picture-icon" alt="40x40" src="@/assets/images/profile/edit_picture_icon.svg" data-holder-rendered="true" @click="active_upload_profile_pic()" />
              </b-col>
              <b-col lg="10" />
            </b-row>
            <ModalUpload ref="Modal_Upload_file" />
            <ValidationObserver ref="edit_profile_form">
              <b-row>
                <b-col lg="6">
                  <div class="form-group">
                    <label for="firstname">{{ $t('register_transporter.information.name') }}</label>
                    <ValidationProvider v-slot="{ failed }" name="firstname" :disabled="disableEdit" rules="">
                      <b-form-input id="firstname" v-model="formData.firstname" type="text" class="form-control" name="firstname" :placeholder="placeholder.name" :class="{ isInvalid: failed }" @keypress="keyThaiEng($event)" />
                      <b-form-invalid-feedback id="firstname-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.name') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label for="lastname">{{ $t('register_transporter.information.lastname') }}</label>
                    <ValidationProvider v-slot="{ failed }" name="lastname" :disabled="disableEdit" rules="">
                      <b-form-input id="lastname" v-model="formData.lastname" type="text" class="form-control" name="lastname" :placeholder="placeholder.lastname" :class="{ isInvalid: failed }" @keypress="keyThaiEng($event)" />
                      <b-form-invalid-feedback id="lastname-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.name') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <div class="form-group">
                    <label for="user_type">{{ $t('register_transporter.information.user_type') }} </label><span style="color: red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                    <ValidationProvider v-slot="{ failed }" name="user_type" :disabled="disableEdit" rules="required">
                      <multiselect v-model="mover_type_sel" label="name" :options="mover_type" :placeholder="placeholder.mover_type" :class="{ isInvalid: failed }" @input="company_sel()" />
                      <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                      <b-form-invalid-feedback id="mover_type-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_type') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="6" :style="{ display: input_display3 ? '' : 'none' }">
                  <div class="form-group">
                    <label for="mover_type">{{ $t('register_transporter.information.mover_type') }} </label>
                    <ValidationProvider v-slot="{ failed }" name="user_type" :disabled="disableEdit" rules="">
                      <multiselect v-model="dataBusinessType_sel" :label="dataBusinessType_label" :options="dataBusinessType_master" :placeholder="placeholder.mover_type" :class="{ isInvalid: failed }" @input="company_sel()" />
                      <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                      <b-form-invalid-feedback id="mover_type-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_type') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
              </b-row>
              <b-row :style="{ display: row_display ? '' : 'none' }">
                <b-col lg="6" :style="{ display: input_display3 ? '' : 'none' }">
                  <div class="form-group">
                    <label for="mover_name">{{ $t('register_transporter.information.mover_name') }}</label><span style="color: red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                    <ValidationProvider v-slot="{ failed }" name="mover_name" :disabled="disableEdit" rules="required">
                      <b-form-input id="mover_name" v-model="formData.mover_name" type="text" class="form-control" name="mover_name" :placeholder="placeholder.mover_name" :class="{ isInvalid: failed }" />
                      <b-form-invalid-feedback id="mover_name-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_name') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="6" :style="{ display: input_display ? '' : 'none' }">
                  <div class="form-group">
                    <label for="mover_grant_num" :style="{ display: input_display3 ? 'block' : 'none' }">{{ $t('register_transporter.information.mover_grant_num') }} </label>
                    <label for="id_card" :style="{ display: input_display2 ? 'block' : 'none' }">{{ $t('register_transporter.information.id_card') }} </label>
                    <ValidationProvider v-slot="{ failed }" name="mover_grant_num" :disabled="disableEdit" rules="">
                      <b-form-input id="mover_grant_num" v-model="formData.mover_grant_num" type="number" class="form-control" name="mover_grant_num" :placeholder="placeholder.mover_grant_num" :class="{ isInvalid: failed }" />
                      <b-form-invalid-feedback id="mover_grant_num-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ valid_msg.mover_grant_num }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label for="mover_tel">{{ $t('register_transporter.information.mover_tel') }}</label><span style="color: red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                    <ValidationProvider v-slot="{ failed }" name="mover_tel" :disabled="disableEdit" rules="required">
                      <b-form-input id="mover_tel" v-model="formData.mover_tel" type="number" class="form-control" name="mover_tel" :placeholder="placeholder.mover_tel" :class="{ isInvalid: failed }" />
                      <b-form-invalid-feedback id="mover_tel-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_tel') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label for="mailaddr">{{ $t('register_transporter.information.mailaddr') }}</label><span style="color: red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                    <ValidationProvider v-slot="{ failed }" name="mailaddr" :disabled="disableEdit" rules="email|required">
                      <b-form-input id="mailaddr" v-model="formData.mailaddr" type="email" class="form-control" name="mailaddr" :placeholder="placeholder.mailaddr" :class="{ isInvalid: failed }" />
                      <b-form-invalid-feedback id="mover_tel-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mailaddr') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label for="mover_address1">{{ $t('register_transporter.information.mover_address') }}</label>
                    <input id="mover_address1" v-model="formData.mover_address1" type="text" class="form-control" name="mover_address1" :placeholder="placeholder.mover_address" />
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="form-group">
                    <label for="mover_province">{{ $t('register_transporter.information.mover_province') }} </label>
                    <ValidationProvider v-slot="{ failed }" name="mover_province" :disabled="disableEdit" rules="">
                      <multiselect v-model="dataProvince_sel" label="name_th" :options="dataProvince_data" :placeholder="placeholder.mover_province" :class="{ isInvalid: failed }" @input="province_sel()" />
                      <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                      <b-form-invalid-feedback id="mover_province-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_province') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="form-group">
                    <label for="mover_district">{{ $t('register_transporter.information.mover_district') }} </label>
                    <ValidationProvider v-slot="{ failed }" name="mover_district" :disabled="disableEdit" rules="">
                      <multiselect v-model="dataAmphur_sel" label="name_th" :options="dataAmphur_data" :placeholder="placeholder.mover_district" :class="{ isInvalid: failed }" @input="amphur_sel()" />
                      <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                      <b-form-invalid-feedback id="mover_district-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_district') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="form-group">
                    <label for="mover_district2">{{ $t('register_transporter.information.mover_district2') }} </label>
                    <ValidationProvider v-slot="{ failed }" name="mover_district2" :disabled="disableEdit" rules="">
                      <multiselect v-model="dataTambol_sel" label="name_th" :options="dataTambol_data" :placeholder="placeholder.mover_district2" :class="{ isInvalid: failed }" @input="tambol_sel()" />
                      <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                      <b-form-invalid-feedback id="mover_district2-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_district2') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="form-group">
                    <label for="mover_postcode">{{ $t('register_transporter.information.mover_postcode') }} </label>
                    <ValidationProvider v-slot="{ failed }" name="zipcode" :disabled="disableEdit" rules="">
                      <b-form-input id="zipcode" v-model="zipcode" type="number" class="form-control" name="zipcode" :class="{ isInvalid: failed }" :disabled="disable_zipcode_input" @input="zipcode_input()" />
                      <b-form-invalid-feedback id="mover_postcode-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_postcode') }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </b-col>
              </b-row>
            </ValidationObserver>
            <br />
            <b-row>
              <b-col>
                <div class="form-group d-flex justify-content-center align-items-center big_btn_blue" @click="check_edit_profile()">
                  <span class="font-size-16">
                    {{ lang.btn.ok }}
                  </span>
                </div>
              </b-col>
              <b-col>
                <div class="form-group d-flex justify-content-center align-items-center big_btn_red" @click="active_edit_profile()">
                  <span class="font-size-16">
                    {{ lang.btn.cancel }}
                  </span>
                </div>
              </b-col>
              <b-col />
              <b-col />
            </b-row>
          </div>
        </simplebar>
      </div>
      <div v-else-if="edit_profile_status == 1" name="profile_1">
        <b-row>
          <!-- personer information -->
          <b-col class="personal-border">
            <b-row>
              <b-col>
                <simplebar id="personer_info" style="max-height: 82.5vh; min-height: 82.5vh">
                  <div class="personal-body">
                    <b-row class="col-margin-bot-2">
                      <b-col>
                        <span class="personal-title">
                          {{ lang.info.title }}
                        </span>
                      </b-col>
                      <b-col class="d-flex align-items-center justify-content-end">
                        <div @click="active_edit_profile()">
                          <div class="edit_icon d-flex align-items-center">
                            <img class="image-main" src="@/assets/images/profile/edit_icon.png" width="20px" height="20px" style="margin-right: 0.5rem" />
                            <img class="image-hover" src="@/assets/images/profile/edit_icon_2.png" width="20px" height="20px" style="margin-right: 0.5rem" />
                            <span class="font-color-blue font_hover font-size-18">
                              {{ lang.info.edit_info }}
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <img v-if="profile == undefined || profile == ''" class="rounded-circle avatar-xl" src="@/assets/images/profile/Group 3361@2x.png" width="140px" height="140px" />
                        <img v-else class="rounded-circle avatar-xl" alt="200x200" :src="profile" data-holder-rendered="true" />
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.name }}
                        </span>
                        <br />
                        <span v-if="personal_data.firstname == '' || personal_data.firstname == 'null' || personal_data.firstname == NULL" class="personal-info"> - </span>
                        <span v-else class="personal-info">
                          {{ personal_data.firstname }}
                        </span>
                        <!-- <span class="personal-info">
                          {{ personal_data.firstname }}
                        </span> -->
                      </b-col>
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.lastname }}
                        </span>
                        <br />
                        <span v-if="personal_data.lastname == '' || personal_data.lastname == 'null' || personal_data.lastname == NULL" class="personal-info"> - </span>
                        <span v-else class="personal-info">
                          {{ personal_data.lastname }}
                        </span>
                        <!-- <span class="personal-info">
                          {{ personal_data.lastname }}
                        </span> -->
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.company_name }}
                        </span>
                        <br />
                        <span class="personal-info">
                          {{ personal_data.mover_name }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.company_type }}
                        </span>
                        <br />
                        <span v-if="personal_data.business_type_name_th" class="personal-info">
                          {{ personal_data.business_type_name_th }}
                        </span>
                        <span v-else class="personal-info">
                          -
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.mover_grant_num }}
                        </span>
                        <br />
                        <span v-if="personal_data.mover_grant_num == ''" class="personal-info"> - </span>
                        <span v-else class="personal-info">
                          {{ personal_data.mover_grant_num }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.mover_tel }}
                        </span>
                        <br />
                        <span class="personal-info">
                          {{ personal_data.mover_tel }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.mailaddr }}
                        </span>
                        <br />
                        <span v-if="personal_data.mover_mailaddr == '' || personal_data.mover_mailaddr == 'null' || personal_data.mover_mailaddr == NULL" class="personal-info"> - </span>
                        <span v-else class="personal-info">
                          {{ personal_data.mover_mailaddr }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info-title">
                          {{ lang.info.address }}
                        </span>
                        <br />
                        <span v-if="mover_address_full == '' || mover_address_full == 'null' || mover_address_full == NULL" class="personal-info"> - </span>
                        <span v-else class="personal-info">
                          {{ mover_address_full }}
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </simplebar>
              </b-col>
            </b-row>
          </b-col>
          <!-- doc and img -->
          <b-col class="personal-border">
            <b-row>
              <b-col>
                <simplebar id="personer_doc_img" style="max-height: 82.5vh; min-height: 82.5vh">
                  <div class="personal-body">
                    <b-row class="col-margin-bot-2">
                      <b-col>
                        <span class="personal-title">
                          {{ lang.doc.title }}
                        </span>
                      </b-col>
                      <b-col class="d-flex align-items-center justify-content-end">
                        <div @click="active_edit_profile_picture()">
                          <div class="edit_icon d-flex align-items-center">
                            <img class="image-main" src="@/assets/images/profile/edit_icon.png" width="20px" height="20px" style="margin-right: 0.5rem" />
                            <img class="image-hover" src="@/assets/images/profile/edit_icon_2.png" width="20px" height="20px" style="margin-right: 0.5rem" />
                            <span class="font-color-blue font_hover font-size-18">
                              {{ lang.info.edit_info }}
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info"> {{ lang.doc.id_card_copy }} / {{ lang.doc.id_mover_copy }} </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col v-if="id_card_fileType == ''">
                        <img src="@/assets/images/profile/temp/file_temp.png" width="550px" height="266px" />
                      </b-col>
                      <b-col v-else-if="id_card_fileType == 'application/pdf'" @click="open_pdf_file()">
                        <img src="@/assets/images/profile/temp/pdf_temp.png" width="550px" height="266px" />
                      </b-col>
                      <b-col v-else>
                        <a class="float-left" @click="() => showImg(0)">
                          <b-col lg="4">
                            <div class="img-fluid">
                              <img :src="id_card" width="550px" height="266px" />
                            </div>
                          </b-col>
                        </a>
                        <vue-easy-lightbox :visible="visible" :index="index" :imgs="id_card" @hide="visible = false" />
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="personal-info"> {{ lang.doc.pic_company }} / {{ lang.doc.pic_company2 }} </span>
                        <span class="font-size-18">
                          {{ lang.doc.pic_up_to }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <div class="popup-gallery d-flex justify-content-center">
                        <div v-for="(item, index3) in lightboximgs_index" :key="index3">
                          <a v-if="lightboximgs[index3] != undefined" class="float-left" @click="() => showImg2(index3)">
                            <b-col lg="4">
                              <div class="img-fluid">
                                <img :src="lightboximgs[index3]" alt width="274" />
                              </div>
                            </b-col>
                          </a>
                          <b-col v-else lg="4">
                            <div class="img-fluid">
                              <img src="@/assets/images/profile/temp/id_card_temp.png" alt width="274" />
                            </div>
                          </b-col>
                        </div>
                      </div>
                    </b-row>
                  </div>
                </simplebar>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-show="edit_profile_status == 2" name="profile_2" class="h-100vh personal-edit-panel container-fluid" :class="{ active: edit_profile_status == 2 }" style="padding: 1rem 1rem 1rem 1rem">
        <simplebar style="max-height: 84vh; min-height: 84vh">
          <b-row class="col-margin-bot-2">
            <b-col>
              <span class="personal-title">
                {{ lang.doc.doc_edit }}
              </span>
            </b-col>
          </b-row>
          <b-row class="col-margin-bot-1">
            <b-col>
              <span class="personal-info"> {{ lang.doc.id_card_copy }} / {{ lang.doc.id_mover_copy }} </span>
            </b-col>
          </b-row>
          <b-row class="col-margin-bot-1">
            <b-col>
              <vue-dropzone ref="dropzone_file_upload" :options="dropzoneOptions" :use-custom-slot="true">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">{{ lang.id_card.title }}</h3>
                  <div class="subtitle">{{ lang.id_card.sub_title }}</div>
                </div>
              </vue-dropzone>
              <br />
              <hr />
            </b-col>
            <b-col />
          </b-row>
          <b-row class="col-margin-bot-1">
            <b-col>
              <span class="personal-info"> {{ lang.doc.pic_company }} / {{ lang.doc.pic_company2 }} </span>
              <span class="font-size-18">
                {{ lang.doc.pic_up_to }}
              </span>
            </b-col>
          </b-row>
          <b-row class="col-margin-bot-1">
            <b-col>
              <vue-dropzone ref="dropzone_file_upload_2" :options="dropzoneOptions2" :use-custom-slot="true">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">{{ lang.pic_mover.title }}</h3>
                  <div class="subtitle">{{ lang.pic_mover.sub_title }}</div>
                </div>
              </vue-dropzone>
              <br />
              <hr />
            </b-col>
            <b-col />
          </b-row>
          <b-row class="col-margin-bot-2">
            <b-col>
              <div class="form-group d-flex justify-content-center align-items-center big_btn_blue" @click="check_fileUpload()">
                <span class="font-size-16">
                  {{ lang.btn.ok }}
                </span>
              </div>
            </b-col>
            <b-col>
              <div class="form-group d-flex justify-content-center align-items-center big_btn_red" @click="active_edit_profile_picture()">
                <span class="font-size-16">
                  {{ lang.btn.cancel }}
                </span>
              </div>
            </b-col>
            <b-col />
            <b-col />
          </b-row>
        </simplebar>
      </div>
    </div>
    <vue-easy-lightbox :visible="visible2" :index="index2" :imgs="lightboximgs" @hide="visible2 = false" />
  </Layout>
</template>
<style>
@media only screen and (min-width: 500px) {
  body {
    overflow: scroll;
  }
}
@media only screen and (min-width: 1200px) {
  body {
    overflow: hidden;
  }
}
.h-100vh {
  height: 100% !important;
}
hr {
  border: 1px solid #e4e4e4;
}
.personal {
  padding: 1rem;
}
.personal-border {
  border: 1px solid #b0bac4;
  border-radius: 5px;
  max-height: 775px;
  margin: 0.5rem;
}
.personal-body {
  padding: 1rem;
}
.personal-title {
  font-size: 26px;
  font-weight: 500;
  color: #343c48;
}
.personal-info-title {
  font-size: 18px;
  font-weight: 500;
  color: #505d69;
}
.personal-info {
  font-size: 24px;
  font-weight: 500;
  color: #0098d1;
}
.personal-edit-panel {
  /* position: absolute; */
  width: 0%;
  /* transition: 0.5s; */
}
.personal-edit-panel.active {
  /* position: absolute; */
  width: 100%;
}
.edit_icon {
  position: relative;
  width: 120px; /* can be omitted for a regular non-lazy image */
  max-width: 100%;
}

.edit_icon:hover img.image-main {
  opacity: 0;
  /* transition: opacity .2s; */
}
.edit_icon img.image-hover {
  position: absolute;
  /* transition: opacity .2s; */
  opacity: 0;
}
.edit_icon:hover img.image-hover {
  opacity: 1;
}
.edit-picture-icon {
  position: absolute;
  top: 80%;
  left: 40%;
}
.edit-picture-icon:hover {
  cursor: pointer;
}
.vue-dropzone:hover .dropzone-custom-title {
  color: #03aeef;
}
.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: #fff;
  margin-left: 5px;
  padding: 5px;
  top: inherit;
  bottom: 15px;
  border: 2px #fff solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
</style>
