<script>
import i18n from '@/i18n'
import service from '@/server/http_service'
import vue2Dropzone from 'vue2-dropzone'
import global_func from '@/global_function'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง',
    meta: [{ name: 'description' }],
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    obj: {
      type: Object,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('upload_file_modal')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      lang_title: '',
      lang_sub_title: '',
      // file
      file_upload: [],
      pic_data: [],
      file_mode: '',
      index: 0, // default: 0
      lightboximgs: [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        maxFilesize: 5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        maxFiles: 1,
        acceptedFiles: '',
      },
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {},
  methods: {
    active(mode, index) {
      var that = this
      that.$bvModal.show('modal_upload_file')
      this.setConfigUploader(mode, index)
    },
    setConfigUploader(mode, index) {
      // console.log(mode)
      // console.log(index)
      this.file_mode = mode
      this.index = index
      if(this.file_mode == 'profile') {
        this.lang_title = this.lang.profile.title
        this.lang_sub_title = this.lang.profile.sub_title
        this.dropzoneOptions.acceptedFiles = 'image/*'
      } else if(this.file_mode == 'id_card') {
        this.lang_title = this.lang.id_card.title
        this.lang_sub_title = this.lang.id_card.sub_title
        this.dropzoneOptions.acceptedFiles = 'image/*,application/pdf'
      }
    },
    async check_fileUpload() {
      var file = this.$refs.dropzone_file_upload.dropzone.files
      var that = this
      var flag = 0
      if(file.length > 0) {
        that.file_upload = []
        for (let index = 0; index < file.length; index++) {
          let reader = new FileReader()
          const rowFile = file[index]
          // console.log(rowFile)
          await reader.addEventListener(
            'load',
            () => {
              let dataPush = {
                name: rowFile.name,
                data: reader.result,
                type: rowFile.type,
              }
              that.file_upload.push(dataPush)
            // console.log(this.pdfFile)
            },
            false,
          )
          if(rowFile.status == 'success'){ 
            reader.readAsDataURL(rowFile)
          } else {
            flag++
          }
        }
      }
      if(flag == 0) {
        setTimeout(function() {
          that.send_file()
        }, 500)
      } else {
        global_func.alertMessage(this.lang.alert_msg.error_upload, '', 'warning')
      }
    },
    send_file() {
      // console.log(this.file_upload)
      // console.log(this.file_mode)
      // console.log(this.index)
      service
        .postService('editUploadFile', {
          account_data: this.account_data,
          file_upload: this.file_upload,
          mode: this.file_mode,
          index_file: this.index,
        })
        .then(rp => {
          // console.log(rp)
          let status = rp.status
          if(status == 'ok') {
            global_func.alertMessage(this.lang.alert_msg.success, '', 'success')
            this.$bvModal.hide('modal_upload_file')
          } else {
            global_func.alertMessage(this.lang.alert_msg.error_upload, '', 'warning')
          }
        })
    },
  },
}
</script>

<template>
  <div>
    <!-- <span class="font-size-16">ข้อมูลบริษัท : </span> -->
    <!-- <b-link class="font-size-16" href="#" @click="bt_detail">{{ lang.detail }}</b-link> -->
    <!-- <span @click="bt_detail">{{ lang.detail }}</span> -->
    <b-modal id="modal_upload_file" centered size="lg" :title="lang.title" title-class="font-size-24" hide-footer>
      <div class="modal_upload_file-custom">
        <b-row>
          <b-col>
            <vue-dropzone id="dropzone_file_upload" ref="dropzone_file_upload" :options="dropzoneOptions" :use-custom-slot="true">
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">{{ lang_title }}</h3>
                <div class="subtitle">{{ lang_sub_title }}</div>
              </div>
            </vue-dropzone>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="3" />
          <b-col lg="6">
            <div class="form-group d-flex justify-content-center align-items-center big_btn_blue" @click="check_fileUpload()">
              <span class="font-size-16">
                {{ lang.upload }}
              </span>
            </div>
          </b-col>
          <b-col lg="3" />
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.modal_upload_file-custom {
  padding: 1rem 1rem;
}
.modal-scrollbar {
  overflow: hidden scroll;
}

.modal-scrollbar {
  overflow: auto;
}
hr {
  border: 1px solid #e4e4e4;
}
.vue-dropzone:hover .dropzone-custom-title {
  color: #03aeef;
}

.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: #fff;
  margin-left: 5px;
  padding: 5px;
  top: inherit;
  bottom: 15px;
  border: 2px #fff solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
</style>
